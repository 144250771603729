import { bindable, inject }                             from 'aurelia-framework';
import { AppContainer }                                 from 'resources/services/app-container';
import { BaseListViewModel }                            from 'base-list-view-model';
import { DialogService }                                from 'aurelia-dialog';
import { Downloader }                                   from 'resources/services/downloader';
import { ExtraordinaryInspectionsSchedulingRepository } from 'modules/extraordinary-inspections/scheduling/services/repository';
import { FilterFormSchema }                             from 'modules/extraordinary-inspections/scheduling/filter-form-schema';
import { ProcedureStatus }                              from 'modules/procedures/models/procedure-status';
import moment                                           from 'moment';

@inject(AppContainer, DialogService, Downloader, FilterFormSchema, ExtraordinaryInspectionsSchedulingRepository)
export class ListExtraordinaryInspectionsScheduling extends BaseListViewModel {

    listingId = 'extraordinary-inspections-scheduling-listing';

    @bindable headerTitle = 'listing.extraordinary-inspections.scheduling';
    @bindable repository;
    @bindable datatable;
    @bindable legend = [
        { color: 'success', name: 'messages.on-time' },
        { color: 'warning', name: 'messages.less-than-one-month-remaining' },
        { color: 'danger', name: 'messages.overdue' },
    ];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, filterFormSchema, repository) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.repository       = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'extraordinary-inspections.scheduling.manage',
            'extraordinary-inspections.scheduling.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.extraordinary-inspections.extraordinary-inspection',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-calendar3',
                    tooltip: 'form.button.schedule',
                    action:  (row) => this.appContainer.router.navigateToRoute('extraordinary-inspections.scheduling.edit', { id: row.id }),
                    visible: (row) => this.appContainer.authenticatedUser.can(['extraordinary-inspections.scheduling.manage', 'extraordinary-inspections.scheduling.edit'])
                        && row.status.id === ProcedureStatus.AWAITING_SCHEDULING
                        && this.appContainer.authenticatedUser.belongsToInspectingEntity(),
                },
                {
                    icon:    'icon-user-check',
                    tooltip: 'form.button.confirm-schedule',
                    action:  (row) => this.appContainer.router.navigateToRoute('extraordinary-inspections.scheduling.confirm', { id: row.id }),
                    visible: (row) => this.appContainer.authenticatedUser.can(['extraordinary-inspections.scheduling.manage', 'extraordinary-inspections.scheduling.edit'])
                        && row.status.id === ProcedureStatus.AWAITING_SCHEDULE_CONFIRMATION
                        && this.appContainer.authenticatedUser.belongsToMaintenanceCompany(),
                },
            ],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('inspecoes_extraordinarias'),
                },
            ],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    6,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:  'inspecting_entity',
                    name:  'inspecting_entity_data.name',
                    title: 'form.field.eiie',
                },
                {
                    data:            'certificate_deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.certificate_deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
                {
                    data:  'status',
                    name:  'procedure_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param date
     *
     * @return {string}
     */
    processDeadlineColor(date) {
        let deadline        = moment(date);
        let closeToDeadline = moment(date).subtract(1, 'month');
        let now             = moment();

        if (now.isAfter(deadline, 'day')) {
            return 'badge-danger';
        } else if (now.isAfter(closeToDeadline, 'day')) {
            return 'badge-warning';
        }

        return 'badge-success';
    }

}
